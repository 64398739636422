<script setup lang="ts">
import { formatPrice } from "~/helpers/formatters";
import { getRoute } from "~/lib/routes";

const { isOpen } = useShoppingCartView();

const { cartItems, totalPrice, itemsCount } = useShopwareCart();
const { onBeginCheckout } = useGtagEvents();

const goToCheckout = async () => {
	onBeginCheckout();
	isOpen.value = false;
};
</script>

<template>
	<ClientOnly>
		<KippieSidebar v-model="isOpen" color="white" placement="right" key="shopping-cart">
			<template #top>
				<button class="flex flex-col gap-2 items-center mx-auto">
					<Typography variant="h2">Winkelmandje</Typography>
					<span class="">
						<span class="text-gray">
							{{ itemsCount }} {{ itemsCount === 1 ? "artikel" : "artikelen" }} |
						</span>
						<span>{{ formatPrice(totalPrice, true) }}</span>
					</span>
				</button>
			</template>
			<div class="flex flex-col gap-6 pb-16 lg:pb-0">
				<ErrorMessageSection />

				<CartItemListSection />

				<CouponSection v-if="cartItems?.length" />

				<Divider />

				<SummarySection />

				<div
					class="!fixed sm:!relative z-20 w-full bottom-2 left-0 p-2 sm:bg-white bg-sand no-underline shadow sm:shadow-none"
				>
					<KippieLink
						:to="getRoute('checkoutLogin')"
						@click="goToCheckout"
						:disabled="cartItems?.length === 0"
						class="no-underline"
					>
						<KippieButton block color="yellow" size="small" :fake="true">
							Doorgaan naar bestellen
						</KippieButton>
					</KippieLink>
				</div>
				<UspSection />
			</div>
		</KippieSidebar>
	</ClientOnly>
</template>
