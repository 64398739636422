<script setup lang="ts">
import type { Icon } from "@kippie/ui/dist/runtime/components";

const items = ref<{ icon: Icon; text: string }[]>([
	{ icon: "shopping-bag", text: "Al je bestellingen op één plek" },
	{ icon: "clock", text: "Kies zelf de dag en tijd die je uitkomt" },
	{ icon: "store", text: "Afhalen bij je dichtstbijzijnde winkel!" }
]);
</script>

<template>
	<KippieUspList title="Bestellen bij Kippie" chicken class="bg-sand">
		<ul class="space-y-4 font-medium">
			<li
				v-for="item in items"
				:key="item.text"
				class="border-b border-brown border-dashed pb-4 last:border-none last:pb-0 flex gap-x-4"
			>
				<SvgIcon :name="item.icon" class="min-w-[24px]" />
				<p class="text-sm leading-6">
					{{ item.text }}
				</p>
			</li>
		</ul>
	</KippieUspList>
</template>
